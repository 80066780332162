import type { Schemas } from "#shopware";
import { getTranslatedProperty } from "@shopware/helpers";

export interface ProductProperty {
  propertyName: string;
  propertyValue: string;
}

export function useProductHelper() {
  const { apiClient } = useShopwareContext();

  function getProductPropertyGroupsByProduct(
    product: Schemas["Product"],
    internals = true,
  ): ProductProperties[] {
    const properties: ProductProperties[] = [];

    for (const property of product?.properties ?? []) {
      const groupName = (property.group as any as Schemas["PropertyGroup"])
        .customFields?.groupName;
      const groupNameSortIndex = (
        property.group as any as Schemas["PropertyGroup"]
      ).customFields?.groupNameSortIndex;

      let group: ProductProperties | undefined = properties.find(
        (x) => x.groupName == groupName,
      );

      if (!group) {
        group = {
          groupName: groupName,
          groupNameSortIndex: groupNameSortIndex,
          properties: [],
        } as ProductProperties;
        properties.push(group);
      }

      const isInternalProp = (property.group as any as Schemas["PropertyGroup"])
        .customFields?.internal;

      if (isInternalProp && !internals) {
        continue;
      }

      group.properties.push({
        propertyName: getTranslatedProperty(property.group, "name"),
        propertyValue: property.name,
      });
    }

    return properties;
  }

  function getProductProptertyGroupNames(product: Schemas["Product"]) {
    const groupNames: string[] = [];

    for (const property of product?.properties ?? []) {
      const groupName = (property.group as any as Schemas["PropertyGroup"])
        .customFields?.groupName;
      if (groupName != undefined && groupName != null && groupName != "") {
        groupNames.push(groupName);
      }
    }
    return groupNames;
  }

  async function getProductPropertyGroupsByProductId(
    id: string,
    internals = false,
  ) {
    let properties: ProductProperties[] = [];

    const { data: result } = await apiClient.invoke(
      "readProduct post /product",
      {
        body: {
          ids: [id],
          associations: {
            properties: {
              associations: {
                group: {},
              },
            },
          },
        },
      },
    );

    let product: Schemas["Product"];

    if (result?.elements) {
      product = result?.elements[0];
      properties = getProductPropertyGroupsByProduct(product, internals);
      return properties;
    }
    return properties;
  }

  async function getProductsByIds(
    ids: string[],
  ): Promise<Schemas["Product"][]> {
    if (ids?.length == 0) return [];
    const { data: result } = await apiClient.invoke(
      "readProduct post /product",
      {
        body: {
          ids: ids,
          associations: {
            properties: {
              associations: {
                group: {},
              },
            },
          },
        },
      },
    );

    const products = result?.elements as Schemas["Product"][];
    return products ?? [];
  }

  async function getProductById(
    id: string,
  ): Promise<Schemas["Product"] | undefined> {
    const result = await getProductsByIds([id]);
    return result?.[0] ?? undefined;
  }

  async function getProductsByIdsWidthParent(ids: string[]) {
    const products = await getProductsByIds(ids);
    for (const product of products) {
      if (product?.parentId) {
        const parent = await getProductById(product.parentId);
        product.parent = parent;
      }
    }
    return products;
  }

  async function getProductByIdWithParent(id: string) {
    const product = await getProductsByIdsWidthParent([id]);
    return product;
  }

  function getDisplayVariant(product: Schemas["Product"]): Schemas["Product"] {
    if (!Array.isArray(product.children)) return product;

    const variants = product.children.sort((a, b) => {
      const aHasStock = (a.activeStock || 0) > 0;
      const bHasStock = (b.activeStock || 0) > 0;

      const aType = a.customFields?.variantType || "zzz";
      const bType = b.customFields?.variantType || "zzz";

      if (aHasStock && !bHasStock) return -1;
      if (!aHasStock && bHasStock) return 1;

      return aType.localeCompare(bType) || 0;
    });

    const firstWithStock = variants.find((c) => (c.availableStock || 0) > 0);
    const firstWithTypeNew = variants.find(
      (c) => c.customFields?.variantType === "new",
    );

    const variant =
      firstWithStock || firstWithTypeNew || variants[0] || product;

    const variantCount = variants.filter(
      (c) => (c.availableStock || 0) > 0,
    ).length;

    variant.name = variant.name || product.name;
    variant.cover = variant.cover || product.cover; // TODO fallback to first media image
    variant.media = variant.media || product.media;

    variant.customFields!.variantCount = variantCount;

    return variant;
  }

  function groupProducts(products: Schemas["Product"][]) {
    const items = new Set(products);
    items.forEach((parent) => {
      if (parent.parentId) return;
      parent.children ??= [];
      items.forEach((child) => {
        if (child.parentId !== parent.id) return;
        if (!parent.children?.some((x) => x.id === child.id))
          parent.children?.push(child);
        items.delete(child);
      });
    });

    return [...items];
  }

  return {
    getProductById,
    getProductByIdWithParent,
    getProductsByIds,
    getProductsByIdsWidthParent,
    getProductProptertyGroupNames,
    getProductPropertyGroupsByProduct,
    getProductPropertyGroupsByProductId,
    getDisplayVariant,
    groupProducts,
  };
}
